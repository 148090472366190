@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic");

@import "../lib/bootstrap-3/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~admin-lte/dist/css/AdminLTE.css";
@import "~admin-lte/dist/css/skins/skin-blue.css";
@import "~bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css";
@import "~datatables.net-bs/css/dataTables.bootstrap.css";
@import "~flatpickr/dist/flatpickr.css";
@import "~simplemde/dist/simplemde.min.css";
@import "~select2/dist/css/select2.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

@import "admin/react-datetime";
@import "admin/forms";

.table {
    td {
        vertical-align: middle !important;
    }

    .table-options-column {
        width: 200px !important;

        &.-extended {
            width: 250px !important;
        }
    }
}

.spaced {
    margin: 0 0.25em;
}

a,
button {
    > .fa {
        margin-right: 0.25em;
    }
}

.muted {
    opacity: 0.5;
}

.label + .label {
    margin-left: 0.2em;
}

.td-collapse {
    padding: 0 !important;
    border-top: none !important;
}

a.disabled {
    color: gray !important;
}

tr.disabled {
    // opacity: 0.4 !important;
}

.cancelled {
    // text-decoration: line-through;

    td:not(:last-of-type) {
        opacity: 0.5 !important;
    }
}

.table.subtable {
    background-color: transparent;
}

.fence {
    border: 1px solid #eee;
    border-radius: 3px;
    overflow: hidden;
    background-color: #f9f9f9;
}

.cursor-pointer {
    cursor: pointer;
}

.d-flex {
    display: flex;
}

.d-inline-block {
    display: inline-block;
}

.label--activity-type-actividades {
    color: white;
    background-color: #fb7564;
}

.label--activity-type-excursiones {
    color: white;
    background-color: #fbc04a;
}

.collapse-indicator {
    transition: all 200ms;

    .collapsed & {
        transform: rotate(0deg);
    }

    transform: rotate(90deg);
}

.collapsible-row {
    &__toggle {
        cursor: pointer;
        transition: all 200ms;

        &.collapsed {
            background-color: white;
        }

        &:hover,
        &:not(.collapsed) {
            background-color: whitesmoke;
        }
    }
}

.mark-color {
    border: 1px solid rgb(255 255 255 / 80%);
    box-shadow: 0 0 0 1px rgb(0 0 0 / 80%);
    border-radius: 2px;
    height: 16px;
    width: 16px;
}
